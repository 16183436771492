import Vue from 'vue'
// import Router from 'vue-router'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '../../assets/font/iconfont.css'
import index from './index.vue'

// Vue.use(Router) // 在vue中注入Router

Vue.config.productionTip = false

new Vue({
    render: h => h(index),
}).$mount('#app')
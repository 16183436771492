<template>
  <div id="cheader">
    <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
      <!-- Indicators -->
      <ul class="carousel-indicators">
        <li data-target="#carousel-example-generic" data-slide-to="0" class="active"></li>

        <li data-target="#carousel-example-generic" data-slide-to="1"></li>
<!-- 
        <li data-target="#carousel-example-generic" data-slide-to="2"></li> -->
      </ul>
      <!-- Wrapper for slides -->

      <div class="carousel-inner" role="listbox">

        <div class="item active">
          <img class="img-responsive" src="../assets/image/ad5_5.jpg" />

          <div class="carousel-caption"></div>
        </div>

        <div class="item">
          <img class="img-responsive" src="../assets/image/ad6_6.jpg" />

          <div class="carousel-caption"></div>
        </div>

        <!-- <div class="item">
          <img
            class="img-responsive"
            src="../assets/image/ad1_1.png"
          />

          <div class="carousel-caption"></div>
        </div>

        <div class="item">
          <img
            class="img-responsive"
            src="../assets/image/ad2_2.png"
          />

          <div class="carousel-caption"></div>
        </div>

        <div class="item">
          <img
            class="img-responsive"
            src="../assets/image/ad3_3.png"
          />

          <div class="carousel-caption"></div>
        </div>-->
      </div>
      <!-- Controls -->

      <a
        class="left carousel-control"
        href="#carousel-example-generic"
        role="button"
        data-slide="prev"
      >
        <span class="glyphicon glyphicon-chevron-left"></span>

        <span class="sr-only">Previous</span>
      </a>

      <a
        class="right carousel-control"
        href="#carousel-example-generic"
        role="button"
        data-slide="next"
      >
        <span class="glyphicon glyphicon-chevron-right"></span>

        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "header",
  data() {
    return {};
  },
  props: {},
  methods: {}
};
</script>

<style scoped>
.img-responsive {
  width: 100%;
}
.carousel-indicators li {
  margin: 5px;
}
</style>

<template>
  <div id="app">
    <cheader></cheader>
    <carousel />

    <div class="content-wrapper">
      <!-- <div class="statistic">
        <div class="row">
          <div class="col-sm-3">
            <span>7,000</span>
            <span>客户</span>
          </div>
        </div>
      </div>-->
      <div class="row" style="margin-left:0;margin-right:0">
        <div class="col-sm-3 content-left">
          <ul>
            <li :class="('全部' == selectedCategory?'active':'')" @click="changeCategory('全部')">
              <i class="iconfont iconshangdian"></i>全部
            </li>
            <li :class="('热门' == selectedCategory?'active':'')" @click="changeCategory('热门')">
              <i class="iconfont iconremen"></i>热门
            </li>
            <li
              :class="(item.name == selectedCategory)?'active':''"
              v-for="item in categoryList"
              v-bind:key="item.name"
              @click="changeCategory(item.name)"
            >
              <i class="iconfont" :class="item.icon" style="padding-right:20px"></i>
              {{item.name}}
            </li>
          </ul>
        </div>
        <div class="col-sm-9 content-right">
          <dir class="search-wrapper">
            <div class="search-input-content">
              <div class="search-input">
                <span>
                  <i style="color:#999;font-size:14px" class="glyphicon glyphicon-search"></i>
                </span>
                <input
                  v-model="searchName"
                  @keyup.enter="searchProduct"
                  placeholder="请输入行业,软件名称 如：教育, 商城"
                  name
                  type="text"
                  value
                />
                <button class="search-button" @click="searchProduct">搜索</button>
              </div>
              <div class="search-hot" style="color:#333">
                热门搜索:
                <a
                  v-for="item in hotProductSearchKey"
                  v-bind:key="item.name"
                  @click="filterProductByName(item.name)"
                >{{item.name}}</a>
              </div>
            </div>
          </dir>
          <div class="row">
            <div
              class="col-sm-4 product-wrapper"
              v-for="(product,index) in displayProductList"
              v-bind:key="product.name"
              v-on:mouseenter="productHover(index,true)"
              v-on:mouseleave="productHover(index,false)"
              @click="goProduct(product.id)"
            >
              <div class="product product-hover" v-if="product.hover">{{product.description}}</div>
              <div
                class="product"
                v-if="!product.hover"
                :style="{'background-image':'url('+product.image+')'}"
              >
                <span
                  v-if="product.hot"
                  class="label"
                  style="position: absolute;top:5px;left:20px;background-color:#ff0000;opacity:0.9"
                >热门</span>
                <!-- <i class="iconfont" :class="product.icon"></i> -->
                <p>{{product.name}}</p>
              </div>
            </div>
            <div
              class="row"
              style="margin-top:50px;margin-bottom:200px;text-align:center"
              v-if="displayProductList.length==0"
            >
              <div>
                <span>
                  <i style="color:#e5e5e5;font-size:100px" class="glyphicon glyphicon-search"></i>
                </span>
              </div>
              <div style="margin-top:20px">
                <span style="color:#999">暂时木有内容呀～～</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sec-item">
        <div class="sec-title">
          全渠道支持 多终端覆盖
        </div>
        <div class="sec-desc">
          全面支持iOS、Android、WAP、微信公众号、微信小程序、支付宝小程序、头条小程序、抖音小程序
        </div>
        <div class="sec-icon row">
          <div class="sec-icon-item col-sm-2 col-sm-offset-1">
            <img style="width:100%" src="../../assets/image/d1.png" alt="">
          </div>
          <div class="sec-icon-item col-sm-2">
            <img style="width:100%" src="../../assets/image/d2.png" alt="">
          </div>
          <div class="sec-icon-item col-sm-2">
            <img style="width:100%" src="../../assets/image/d3.png" alt="">
          </div>
          <div class="sec-icon-item col-sm-2">
            <img style="width:100%" src="../../assets/image/d4.png" alt="">
          </div>
          <div class="sec-icon-item col-sm-2">
            <img style="width:100%" src="../../assets/image/d5.png" alt="">
          </div>
        </div>
      </div>
      <div class="sec-item">
        <div class="sec-title">线上线下融合，数字化营销，精细化管控，重新定义消费场景</div>
        <div class="sec-desc">线上商城：具备线上购物所有功能体系，同时具有丰富的线上营销功能，大数据驱动业务成长</div>
        <div class="sec-biz">
          <div class="sec-biz-item" style="background-color: #FAA05A">
            <div class="sec-biz-item-title">全渠道营销</div>
            <div class="sec-biz-item-desc">PC+APP+微信商城+第三方平台，打造全渠道销售体系</div>
          </div>
          <div class="sec-biz-item" style="background-color: #69CBEF">
            <div class="sec-biz-item-title">社交化流量引擎</div>
            <div class="sec-biz-item-desc">通过注册送红包、拼团、砍价、推广返利等社交化电商手段为线上商城引流</div>
          </div>
          <div class="sec-biz-item" style="background-color: #F883C8">
            <div class="sec-biz-item-title">多样特色功能</div>
            <div class="sec-biz-item-desc">电子会员、自由购、移动支付等当下热门的功能，增加会员黏性</div>
          </div>
          <div class="sec-biz-item" style="background-color: #9691F5">
            <div class="sec-biz-item-title">丰富营销手法</div>
            <div class="sec-biz-item-desc">丰富的营销手法，让您的商城促销玩出新花样，提高复购率</div>
          </div>
          <div class="sec-biz-item" style="background-color: #F36B6B">
            <div class="sec-biz-item-title">大数据指导</div>
            <div class="sec-biz-item-desc">会员、商品、订单掌握大数据，才能把握商城平台的运营要点，保证平台长期稳定发展</div>
          </div>
        </div>
      </div>
      <div class="sec-item">
        <div class="sec-title">
          技术驱动 改变电商
        </div>
        <div class="sec-desc">
          将前沿软件技术与行业应用相结合，为大规模电商应用提供强有力的支持
        </div>
        <div class="tech">
          <div class="row tech-row">
            <div class="col-sm-3 tech-item">
              <div class="title">专注互联网领域</div>
              <div class="desc">专注于互联网软件的研发，坚持精雕细琢，致力于为企业打造高品质互联网软件系统<p/>坚持互联网软件的研发及服务，紧跟互联网发展趋势，不断追求完美和极致，致力于为企业打造拥有核心竞争力的生态系统。</div>
            </div>
            <div class="col-sm-3 tech-item">
              <div class="title">标准开发规范</div>
              <div class="desc">严格遵循Java标准开发规范，完善的代码注释，标准的命名规范，统一的编程风格<p/>我们始终坚持用严苛的技术标准打造软件品牌价值，深入电商软件开发的每一个细节，100%源码支持，详细代码注释及文档，让二次开发更简单。</div>
            </div>
            <div class="col-sm-3 tech-item">
              <div class="title">主流技术研发</div>
              <div class="desc">基于SpringBoot、JPA、Bootstrap、Vue、Uniapp等国际主流技术研发

<p/>创创猫基于企业级Java技术开发，采用SpringBoot、JPA等国际主流架构，有效保障系统的稳定性和可靠性。</div>
            </div>
            <div class="col-sm-3 tech-item">
              <div class="title">行业品牌优势</div>
              <div class="desc">成功为多家企业、金融机构、政府单位提供解决方案，拥有丰富的行业项目经验<p/>多个项目在运营过程中表现优异并获得客户的一致好评。
</div>
            </div>
          </div>
        </div>
      </div>
      <div class="sec-item">
        <div class="sec-title">行业覆盖 完美契合</div>
        <div class="sec-desc">为各行各业赋能，扩大市场，增强企业盈利能力</div>
        <div class="sec-icon row">
          <div class="col-sm-2">
            <div class="sec-item-field" style="background-color: #FAA05A">鞋服运动</div>
          </div>
          <div class="col-sm-2">
            <div class="sec-item-field" style="background-color: #69CBEF">珠宝首饰</div>
          </div>
          <div class="col-sm-2">
            <div class="sec-item-field" style="background-color: #F883C8">美妆</div>
          </div>
          <div class="col-sm-2">
            <div class="sec-item-field" style="background-color: #9691F5">日化个护</div>
          </div>
          <div class="col-sm-2">
            <div class="sec-item-field" style="background-color: #F36B6B">建筑装饰</div>
          </div>
          <div class="col-sm-2">
            <div class="sec-item-field" style="background-color: #69CBEF">家装家居</div>
          </div>
          <div class="col-sm-2">
            <div class="sec-item-field" style="background-color: #69CBEF">数码电器</div>
          </div>
          <div class="col-sm-2">
            <div class="sec-item-field" style="background-color: #F36B6B">食品饮料</div>
          </div>
          <div class="col-sm-2">
            <div class="sec-item-field" style="background-color: #9691F5">农业科技</div>
          </div>
          <div class="col-sm-2">
            <div class="sec-item-field" style="background-color: #F883C8">餐饮住宿</div>
          </div>
          <div class="col-sm-2">
            <div class="sec-item-field" style="background-color: #69CBEF">电子工业</div>
          </div>
          <div class="col-sm-2">
            <div class="sec-item-field" style="background-color: #FAA05A">智能制造</div>
          </div>
        </div>
      </div>
      <div class="sec-item">
        <div class="sec-title">公司实力与服务优势</div>
        <div class="sec-desc">10+年技术沉淀，服务于各行各业，为商家提供稳定的服务</div>
      <div class="sec-icon row">
          <div class="sec-icon-item col-sm-12">
            <img src="../../assets/image/advantage.jpg" style="width: 100%" />
          </div>
        </div>
      </div>
      <div class="sec-item">
        <div class="sec-title">合作客户</div>
        <div class="sec-desc">感知责任，优质回馈，合作共赢</div>
        <div class="sec-icon row" style="background:orange">
          <div class="col-sm-2" v-for="(customer,index) in customerList" v-bind:key="index">
            <div class="sec-item-field">
              <img :src="customer.url" :alt="customer.name" style="width:170px;height:75px">
            </div>
          </div>
        </div>
      </div>
    
    <contact></contact>
    <cfooter></cfooter>
  </div>
</template>

<script>
import cheader from "../../components/cheader.vue";
import carousel from "../../components/carousel-index.vue";
import cfooter from "../../components/cfooter.vue";
import contact from "../../components/contact.vue";
import { categoryList, hotProductSearchKey,customerList } from "../../assets/js/resource.js";
import { getUrlKey } from "../../assets/js/utils.js";
export default {
  name: "app",
  data() {
    return {
      categoryList: categoryList,
      hotProductSearchKey: hotProductSearchKey,
      customerList: customerList,
      displayProductList: [],
      selectedCategory: "全部",
      searchName: ""
    };
  },
  components: { cheader, carousel, cfooter, contact },
  methods: {
    goProduct(id) {
      window.location.href = "/product.html?id=" + id;
    },
    productHover(index, hover) {
      var product = this.displayProductList[index];
      product.hover = hover;
      this.$set(this.displayProductList, index, product);
    },
    searchProduct() {
      var searchName = this.searchName;
      if (searchName) {
        this.filterProductByName(searchName);
      } else {
        this.populateALLProduct();
      }
    },
    changeCategory(category) {
      this.selectedCategory = category;
      if (category == "全部") {
        this.populateALLProduct();
      } else if (category == "热门") {
        this.filterHotProduct();
      } else {
        this.filterProductByCategory(category);
      }
    },
    populateALLProduct() {
      var displayProductList = [];
      this.categoryList.forEach(function(category) {
        category.products.forEach(function(product) {
          displayProductList.push(product);
        });
      });
      this.displayProductList = displayProductList;
    },
    filterProductByCategory(selectedCategory) {
      var displayProductList = [];
      this.categoryList.forEach(function(category) {
        if (selectedCategory == category.name) {
          category.products.forEach(function(product) {
            displayProductList.push(product);
          });
        }
      });
      this.displayProductList = displayProductList;
    },
    filterProductByName(searchName) {
      var displayProductList = [];
      this.categoryList.forEach(function(category) {
        category.products.forEach(function(product) {
          if (product.name.indexOf(searchName) != -1)
            displayProductList.push(product);
        });
      });
      this.displayProductList = displayProductList;
    },
    filterHotProduct() {
      var displayProductList = [];
      this.categoryList.forEach(function(category) {
        category.products.forEach(function(product) {
          if (product.hot) {
            displayProductList.push(product);
          }
        });
      });
      this.displayProductList = displayProductList;
    }
  },
  created() {
    let isHot = getUrlKey("isHot");
    if (isHot) {
      this.filterHotProduct();
    } else {
      this.populateALLProduct();
    }
  }
};
</script>

<style>
body {
  font-family: Arial, "microsoft yahei", Helvetica, sans-serif;
  background-color: #f3f3f3;
}
.statistic {
  width: 500px;
  height: 100px;
  text-align: center;
}
.content-wrapper {
  margin: -0 20px 20px 20px;
}
.content-left {
  background-color: #fff;
}
.content-left ul {
  list-style: none;
  padding: 10px 0 0 0;
}
.content-left ul li {
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 60px;
  padding-left: 20px;
  cursor: pointer;
}
.content-left i {
  padding-right: 20px;
}
.content-left .active {
  background: rgba(249, 128, 32, 1);
  color: #fff;
}
.content-left ul li:hover {
  background: rgb(250, 167, 101);
  color: #fff;
}
.content-right {
}
.product-wrapper {
  background-color: #f3f3f3;
  cursor: pointer;
}
.product {
  background-size: cover;
  background-position: center center;
  color: #fff;
  font-size: 20px;
  height: 300px;
  text-align: center;
  padding: 125px 20px 100px 20px;
  margin-bottom: 30px;
  transition: background-image 0.3s ease-in;
}
.product-hover {
  background-image: url(../../assets/image/product-hover-bg.jpg);
  transition: background-image 0.3s ease-in;
}
.product i {
  font-size: 30px;
}
.product-online-shopping {
  background-image: url(../../assets/image/online-shopping.png);
}
.product-life {
  background-image: url(../../assets/image/life.png);
}
.product-food {
  background-image: url(../../assets/image/food.png);
}
.product-hair {
  background-image: url(../../assets/image/hair.png);
}
.product-shooting {
  background-image: url(../../assets/image/shooting.png);
}
.product-store {
  background-image: url(../../assets/image/store.png);
}
.product-entertainment {
  background-image: url(../../assets/image/entertainment.png);
}
.product-market {
  background-image: url(../../assets/image/market.png);
}
.product-training {
  background-image: url(../../assets/image/market.png);
}
.product-bbs {
  background-image: url(../../assets/image/bbs.png);
}
.product-tour {
  background-image: url(../../assets/image/tour.png);
}
.product-car {
  background-image: url(../../assets/image/car.png);
}
.search-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  /* background-color: #fff; */
  /* background-image:linear-gradient(to right, #DFBAA0 , #F98020); */
}
.search-input-content {
  margin: 0px 0 10px 0;
  padding: 10px 0 10px 0;
}
.search-hot {
  overflow: hidden;
  margin: 20px 15% 0 15%;
  font-size: 14px;
}
.search-hot a {
  color: #333;
  cursor: pointer;
  padding-left: 10px;
}
.search-hot a:hover {
  color: #f98020;
  text-decoration: none;
}
.search-input {
  overflow: hidden;
  margin: 20px 15% 20px 15%;
  display: flex;
}
.search-input span {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding-left: 10px;
  background-color: #fff;
}
.search-input input {
  outline: none;
  height: 50px;
  line-height: 50px;
  width: 70%;
  border: 0;
  padding: 18px;
}
.search-button {
  height: 50px;
  line-height: 50px;
  width: 100px;
  border: 0;
  background-color: #09b6fe;
  color: #fff;
}
.func {
  background-color: #FFF;
  padding: 20px;
}
.func-row {
  width: 100%;
  padding-top: 80px;
}
.func-row-left {
  text-align: center;
}
.func-row-left img {
  width: 100%;
}
.func-row-right {
  text-align: center;
}
.func-row-right-title {
  font-size: 30px;
  color: #333;
}
.func-row-right-desc {
  margin: 20px;
  font-size: 15px;
  color: #666;
}
.sec-item{
  padding: 40px;
  margin-top: 60px;
}
.sec-item-field{
  text-align: center;
  font-weight: bold;
  padding: 20px;
  margin: 15px;
  font-size: 20px;
  color: #fff;
  
}
.sec-item-field:hover{
  transform: scale(1.1)
}
.tech{
  width: 100%;
  background-image: url(../../assets/image/tour.png);
}
.tech-row{
  background-color: rgba(0, 0, 0, 0.1);
}
.tech-item{
  height: 500px;
  color: #fff;
  text-align: center;
  padding: 100px 50px;
}
.tech-item .title{
  font-size: 20px;
}
.tech-item .desc{
  margin-top: 30px;
  font-size: 16px;
  text-align: left;
  letter-spacing: 1px;
  line-height: 30px;
}
.sec-title{
  text-align: center;
  color: #333;
  font-size: 40px;
}
.sec-desc{
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  color: #666;
  font-size: 20px;
}
.mt200{
  margin-top: 200px;
}
.sec-biz {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.sec-biz-item {
  text-align: center;
  padding: 40px 20px 40px 20px;
  margin: 20px;
  color: #fff;
  width: 220px;
  height: 300px;
  border-radius: 10px;
}
.sec-biz-item-title {
  font-size: 25px;
}
.sec-biz-item-desc {
  margin-top: 40px;
}
</style>
